import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/products/banner-produk.png"
import Seo from "../../components/seo"

const IndigenRealtime = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="DIAGO T790M Mutation Detection Kit" />

      <Header productsPage />

      <BannerHeader
        background={bg}
        title="DIAGO T790M Mutation Detection Kit"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="">
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/indigen-real-time">
                        INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products/diago-t790m">
                        DIAGO T790M Mutation Detection Kit
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/products/diagoNew.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="row m-b30">
                      <div class="col-lg-12">
                        <div class="dlab-tabs  product-description tabs-site-button">
                          <h4 className="dlab-title mt-2">
                            DIAGO T790M Mutation Detection Kit
                          </h4>
                          <Tabs>
                            <TabList className="nav nav-tabs ">
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Description</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Test Principle</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Intended Use</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Additional Information</Link>
                              </Tab>
                            </TabList>

                            <TabPanel class="tab-pane">
                              <p className="text-justify">
                                EGFR mutation is known as the highest mutation
                                in lung cancer. Most of the point mutations in
                                the EGFR gene are associated with exon 19
                                deletions, and exon 20 L858R substitutions are
                                up to 80%. Lung cancer patients with EGFR
                                mutations will use first-line Tyrosine Kinase
                                Inhibitors (TKI) to treat these mutations. At
                                the time of drug consumption, the patient showed
                                symptoms of relapse due to drug resistance
                                mechanisms (T790M mutation). In Indonesia, the
                                prevalence of the T790M mutation was reported to
                                be higher, up to 48.7% after consumption of
                                first-line TKI compared to the initial T790M
                                mutation, which was only 5%. A single and
                                non-invasive detection of T790M to monitor the
                                success of first-line TKI treatment is urgently
                                needed. The DIAGO T790M Mutation Detection Kit
                                is an in-vitro diagnostic kit that has the
                                ability to detect T790M mutations in lung cancer
                                patients after first-line TKI treatment. The
                                sample is collected by a non-invasive procedure
                                from the patient's blood (Cell-Free DNA). This
                                kit amplifies the DNA fragment from T790M using
                                a double-labeled probe to provide high
                                sensitivity and specificity. DIAGO T790M
                                Mutation Detection Kit is an open system with a
                                minimum of 2 channels (FAM & CY5) and can be
                                used on various machines that have been widely
                                distributed nationally after the Covid-19
                                pandemic (PCR Utilization).
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <StaticImage
                                src="../../images/products/diagoUse.png"
                                layout="fullWidth"
                                height={300}
                              />
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                DIAGO T790M Mutation Detection Kit is intended
                                for the qualitative detection of T790M mutation
                                for a lung cancer patient that has a recurrence
                                condition after first-line therapy. This test is
                                required for clinicians to determine suitable
                                treatment for second-line TKI. This kit can be
                                operated in the biomolecular laboratory by a
                                well-trained molecular analyst.
                              </p>
                            </TabPanel>

                            <TabPanel>
                              <p class="m-b10 font-weight-bold">
                                Reference Article:
                              </p>
                              <p className="ml-4">
                                <ul>
                                  <li>
                                    Syahruddin E, Wulandari L, Sri Muktiati N,
                                    Rima A, Soeroso N, Ermayanti S, Levi M,
                                    Hidajat H, Widjajahakim G, Utomo ARH.
                                    Uncommon EGFR mutations in cytological
                                    specimens of 1,874 newly diagnosed
                                    Indonesian lung cancer patients. Lung Cancer
                                    (Auckl). 2018 Mar 23;9:25-34
                                  </li>
                                  <li>
                                    Han B, Tjulandin S, Hagiwara K, Normanno N,
                                    Wulandari L, Laktionov K, Hudoyo A, He Y,
                                    Zhang YP, Wang MZ, Liu CY, Ratcliffe M,
                                    McCormack R, Reck M. EGFR mutation
                                    prevalence in Asia-Pacific and Russian
                                    patients with advanced NSCLC of
                                    adenocarcinoma and non-adenocarcinoma
                                    histology: The IGNITE study. Lung Cancer.
                                    2017 Nov;113:37-44.
                                  </li>
                                  <li>
                                    Yatabe Y, Kerr KM, Utomo A, Rajadurai P,
                                    Tran VK, Du X, Chou TY, Enriquez ML, Lee GK,
                                    Iqbal J, Shuangshoti S, Chung JH, Hagiwara
                                    K, Liang Z, Normanno N, Park K, Toyooka S,
                                    Tsai CM, Waring P, Zhang L, McCormack R,
                                    Ratcliffe M, Itoh Y, Sugeno M, Mok T. EGFR
                                    mutation testing practices within the Asia
                                    Pacific region: results of a multicenter
                                    diagnostic survey. J Thorac Oncol. 2015
                                    Mar;10(3):438-45.
                                  </li>
                                </ul>
                              </p>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default IndigenRealtime
